<template>
  <div v-show="show_points" class="full-height">
    <points @clicked="pointHistoryAction" />
  </div>
  <div v-show="!show_points">
    <page-title title-class :skeleton="false" :title="$t('my_points.title')" />
    <card-header @clicked="pointHistoryAction" />
    <categories @clicked="pointHistoryAction" />
  </div>
</template>

<script>
import PageTitle from "../../components/page-title";
import CardHeader from "./components/CardHeader";
import Categories from "./components/categories";
import Points from "./components/points";
import { ref } from "vue";

export default {
  components: { Points, Categories, PageTitle, CardHeader },

  setup() {
    const show_points = ref(false),
      pointHistoryAction = () => {
        show_points.value = !show_points.value;
      };

    return {
      pointHistoryAction,
      show_points,
    };
  },
};
</script>
