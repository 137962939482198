<template>
  <div class="q-ma-sm">
    <div
      class="
      category-extract      
      cursor-pointer
      q-mt-md q-mb-md
      text-white
      default-card-border default-rounded
    "
      @click="openPointsExtract"
    >
      <div class="row q-pa-lg justify-between content-center">
        <div class="c-icon">
          <q-icon name="receipt_long" />
        </div>
        <div class="title self-center text-white">
          {{ "Visualizar Extrato" }}
        </div>
        <div class="row self-center justify-between">
          <q-icon class="q-ml-lg self-center" size="2em" name="navigate_next" />
        </div>
      </div>
    </div>
    <div
      class="text-uppercase text-grey-8 q-mt-lg q-mb-none"
      style="font-size: 14pt;"
    >
      {{ $t("my_points.credits_by_category") }}
    </div>
    <div v-if="categories.length">
      <div v-if="in_request">
        <category-skeleton v-for="key in 5" :key="key" />
      </div>
      <div v-else>
        <category
          v-for="category in categories"
          :key="category.id"
          :category="category"
          @clicked="$emit('clicked')"
        />
      </div>
    </div>
    <div v-else>
      <div class="bg-grey-4 text-center" style="padding: 100px;">
        {{ $t("my_points.no_categories") }}
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import CategorySkeleton from "./categorySkeleton";
import Category from "./category";
import { ref } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("my_points");

export default {
  name: "Categories",
  
  components: { CategorySkeleton, Category },

  emits: ["clicked"],

  setup(props, root) {
    const in_request = ref(true),
      { categories } = useGetters({ categories: "getCategories" }),
      { fetchCategory } = useActions(["fetchCategory"]);

    fetchCategory().finally(() => (in_request.value = false));

    return {
      categories,
      in_request,
      openPointsExtract: () => {
        root.emit("clicked");
      },
    };
  },
};
</script>

<style scoped lang="scss">
.category-extract {
  background: rgb(144, 144, 144);
  font-size: 1em;
  font-weight: 500;
  box-shadow: none;
  .text-counter-size {
    font-size: 1em;
    font-weight: 500;
    color: #222;
  }
  .c-icon {
    background: #222;
    color: white;
    font-size: 2em;
    border-radius: 50% !important;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.desktop {
  .category-extract {
    .row {
      flex-wrap: nowrap;
      .title {
        width: 100%;
        margin-left: 20px;
        font-size: 1.5em;
        color: #4e4e4e;
      }
      .text-counter-size {
        font-size: 1.5em;
        color: #4e4e4e;
      }
    }
  }
}
</style>
