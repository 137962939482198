<template>
  <div
    class="
      category
      cursor-pointer
      q-mt-lg q-mb-lg
      default-card-border default-rounded
    "
    :class="{ 'no-pointer': !category.total_points }"
    @click="openPointsExtract"
  >
    <div class="row q-pa-lg justify-between content-center">
      <div class="c-icon">
        <q-icon :name="category.icon" />
      </div>
      <div class="title self-center" style="text-transform: capitalize;">
        {{ category.description }}
      </div>
      <div class="row self-center justify-between">
        <points-counter class="self-center" :value="category.total_points" />
      </div>
    </div>
  </div>
</template>

<script>
import PointsCounter from "./pointsCounter";

export default {
  name: "Category",

  components: { PointsCounter },

  props: {
    category: {
      require: true,
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
.category {
  background: white;
  color: #222;
  font-size: 1em;
  font-weight: 500;
  box-shadow: none;
  .text-counter-size {
    font-size: 1em;
    font-weight: 500;
    color: #222;
  }
  .c-icon {
    background: #222;
    color: white;
    font-size: 2em;
    border-radius: 50% !important;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.desktop {
  .category {
    .row {
      flex-wrap: nowrap;
      .title {
        width: 100%;
        margin-left: 20px;
        font-size: 1.5em;
        color: #4e4e4e;
      }
      .text-counter-size {
        font-size: 1.5em;
        color: #4e4e4e;
      }
    }
  }
}
</style>
