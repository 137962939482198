<template>
  <card-header-points @go-back="$emit('clicked')" />
  <points-time-line />
</template>

<script>
import CardHeaderPoints from "./CardHeaderPoints";
import PointsTimeLine from "./PointsTimeLine";

export default {
  name: "Points",

  components: { PointsTimeLine, CardHeaderPoints },
};
</script>
