<template>
  <q-timeline v-if="in_request" color="secondary">
    <q-timeline-entry v-for="key in 7" :key="'skeleton-' + key" color="grey-6">
      <div class="row timeline-entry-content justify-between">
        <div>
          <p class="q-timeline-entry-title text-grey-8">
            <q-skeleton class="bg-grey-5" type="text" width="10em" />
          </p>
          <q-skeleton class="bg-grey-5" type="text" width="5em" />
        </div>
        <p class="date">
          <q-skeleton class="bg-grey-5" type="text" width="4em" />
        </p>
      </div>
    </q-timeline-entry>
  </q-timeline>
  <q-timeline v-else color="secondary">
    <template v-if="points.length > 0">
      <q-timeline-entry
        v-for="point in points"
        :key="point.id"
        class="custom-entry"
        :icon="
          point.e_type === 'reward'
            ? 'fas fa-gift'
            : point.e_type === 'point_canceled_after_rescued'
            ? 'cancel'
            : void 1
        "
        :color="point.e_type === 'point' ? 'green-10' : 'pink-12'"
      >
        <template v-slot:subtitle>
          <div class="row no-wrap justify-between">
            <p
              v-if="
                point.e_type === 'point_canceled_after_rescued' ||
                point.e_type === 'reward'
              "
              class="text-pink-12 text-weight-bold"
            >
              {{
                $t(
                  point.e_type === "point_canceled_after_rescued"
                    ? "my_points.point_canceled_after_rescued"
                    : "my_points.point_reward"
                )
              }}
            </p>
            <p
              v-if="point.e_type === 'point'"
              class="text-green-10 text-weight-bold"
            >
              {{ getDescription(point.company_trigger) }}
              <span
                v-if="
                  point.company_trigger.trigger.slug == 'course' ||
                  point.company_trigger.trigger.slug == 'buy' ||
                  point.company_trigger.trigger.slug =='creditos_anteriores'
                "
                class="text-bold"
                style="color: #ababab"
                >[{{
                  $t(
                    point.company_trigger.trigger.slug == "course"
                      ? "my_points.id"
                      : "my_points.order"
                  )
                }}
                {{ getExternalKey(point) }}]</span
              >
            </p>

            <p class="date text-uppercase">
              {{ formatDate(point.created_at) }}
            </p>
          </div>
        </template>
        <div
          v-if="
            point.e_type === 'reward' ||
            point.e_type === 'point_canceled_after_rescued'
          "
          class="row timeline-entry-content no-wrap justify-between"
        >
          <div v-if="point.e_type === 'reward'">
            <p class="text-caption q-mb-sm text-pink-12">
              -{{ point.amount }} {{ $t("my_points.points_rescue") }}
            </p>
            <p
              class="text-caption q-ma-none text-pink-12"
              v-html="
                $t('my_points.you_reward', {
                  type: point.option.title,
                  value: point.amount,
                })
              "
            ></p>
          </div>
          <div v-if="point.e_type === 'point_canceled_after_rescued'">
            <p class="text-caption q-mb-sm text-pink-12">
              -{{ point.points_canceled }} {{ $t("my_points.points_rescue") }}
            </p>
            <p
              class="text-caption q-ma-none text-pink-12"
              v-html="$t('my_points.point_canceled_after_rescued_description')"
            ></p>
          </div>
        </div>
        <div v-else>
          <span
            class="text-caption q-mb-sm text-pink-12"
            v-if="point.points_canceled && point.points_canceled != 0"
          >
            {{
              $t("my_points.point_partially_canceled", {
                points_canceled: point.points_canceled,
                amount: point.amount,
              })
            }}</span
          >
          <p class="text-caption q-mt-lg text-green-10">
            +{{ point.amount - point.points_canceled }}

            {{
              $t("my_points.points", {
                valid_from: formatDateValidFrom(point.valid_from),
              })
            }}
          </p>
          <p
            class="text-caption q-mt-lg text-green-10"
            v-if="
              point.company_trigger.trigger.show_external_key == 1 &&
              point.event != null
            "
          >
            {{
              $t("my_points.points_message", {
                external_key: point.event.external_key,
              })
            }}
          </p>
        </div>
      </q-timeline-entry>
    </template>
  </q-timeline>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { onMounted } from "vue";
import moment from "moment";

const { useActions, useGetters } = createNamespacedHelpers("my_points");

export default {
  name: "PointsHistory",

  setup() {
    const { points, in_request } = useGetters({
        points: "getPoints",
        in_request: "isPointerInRequest",
      }),
      { fetchPoints } = useActions(["fetchPoints"]),
      formatDate = (date) => moment(date).format("LLLL"),
      formatDateValidFrom = (date) => moment(date).format("DD/MM/YYYY"),
      getDescription = (obj) => (obj !== null ? obj.trigger.description : "--");

    const getExternalKey = (obj) => {
      if (obj.event) return obj.event.external_key;
    };

    onMounted(() => fetchPoints());

    return {
      in_request,
      points,
      formatDate,
      getDescription,
      getExternalKey,
      formatDateValidFrom,
    };
  },
};
</script>

<style lang="scss">
.custom-entry {
  .q-timeline__subtitle {
    text-transform: initial;
    font-weight: 400;
    opacity: 1;
    font-size: 1em;
    .date {
      font-size: 0.9em;
      color: #ababab;
      margin: 0;
    }
  }
}
.q-timeline__content {
  margin-top: -15px;
}

.timeline-entry-content {
  .q-timeline-entry-title {
    font-size: 0.9em;
    font-weight: 500;
  }
  .date {
    font-size: 0.9em;
    color: #ababab;
    margin: 0;
  }
  .point-win {
    color: #ff42b4;
  }
}
</style>
