<template>
  <div class="row q-mt-lg">
    <div class="col-12 col-md-3">
      <point-card
        class="text-weight-medium"
        :labelMark="$t('Créditos disponíveis para resgate')"
        img="total.png"
        :value="points.currentPoints || 0"
        :in_request="in_request"
      />
    </div>
    <div class="col-12 col-md-3">
      <point-card
        class="text-weight-medium"
        :label-mark="$t('Créditos a expirar')"
        img="expired.png"
        :value="points.pointsTobeExpired || 0"
        :in_request="in_request"
      />
    </div>
    <div class="col-12 col-md-3">
      <point-card
        class="text-weight-medium"
        :label-mark="$t('Créditos em análise')"
        img="analyze.png"
        :value="points.pointsToBeReleased || 0"
        :in_request="in_request"
      />
    </div>
    <div class="col-12 col-md-3">
      <point-card
        class="text-weight-medium"
        :label-mark="$t('Total de créditos não resgatados')"
        img="total.png"
        :value="points.pointsToBeReleased + points.currentPoints || 0"
        :in_request="in_request"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useGetters, useActions } = createNamespacedHelpers("my_points");
import PointCard from "./PointCard";
import { ref } from "vue";

export default {
  name: "CardHeader",

  components: {
    PointCard,
  },

  setup() {
    const in_request = ref(true),
      { points } = useGetters({
        points: "getTotalPoints",
      }),
      { fetchTotalPoints } = useActions(["fetchTotalPoints"]);

    fetchTotalPoints().finally(() => (in_request.value = false));

    return {
      points,
      in_request,
    };
  },
};
</script>

<style lang="scss">
.point-content {
  background: #222;
  border-radius: 11px;
  padding: 6px;
  zoom: 0.7;
  .text-counter-size {
    color: white;
  }
}
.desktop {
  .card-header-wrapper {
    margin-bottom: 50px;
    margin-top: 50px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    .card-header {
      margin-right: 20px;
      width: 100%;
      background: white;
      .text-cs-title {
        span {
          border-bottom: 2px solid #5882e0;
        }
      }
      .column {
        align-content: start;
      }
      .point-content {
        background: transparent;
        padding: 5px;
        .text-counter-size {
          color: black !important;
          font-size: 2em;
        }
      }
    }
    .row {
      margin: 0;
      button {
        font-size: 1em !important;
      }
    }
  }
}
</style>
