<template>
  <div class="card-header bg-white default-rounded q-ma-sm">
    <div class="column text-uppercase q-pa-md">
      <p class="text-cs-title label-color">
        <span>{{ labelMark }}</span>
        {{ label }}
      </p>
      <div>
        <q-skeleton
          v-if="in_request"
          class="absolute-top"
          width="100%"
          height="100%"
          animation="blink"
        />
        <div class="row">
          <div class="col-2">
            <q-img :src="getImg(img)" class="img" />
          </div>
          <div class="col-10 q-pt-sm">
            <points-counter v-if="in_request" class="q-ml-lg" :value="0" />
            <points-counter v-else class="q-ml-lg text" :value="value" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PointsCounter from "./pointsCounter";

export default {
  name: "PointCard",

  components: { PointsCounter },

  emits: ["clicked"],

  props: {
    value: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelMark: {
      type: String,
      required: true,
    },
    in_request: {
      type: Boolean,
      required: true,
    },
    img: {
      type: String,
    },
  },

  setup() {
    return {
      getImg: (img) => `/dash-icons/${img}`,
    };
  },
};
</script>

<style scoped lang="scss">
.label-color {
  color: #79899d;
}

.label-mark {
  padding-bottom: 6px;
  border-bottom: 2px solid #4b91c0;
}
.img {
  margin-left: 10px;
  margin-top: 5px;
  width: 3.2em;
}
</style>
