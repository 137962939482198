<template>
  <div class="category q-mt-lg q-mb-lg default-card-border default-rounded">
    <div class="row q-pa-lg justify-between content-center">
      <q-skeleton class="bg-grey-5" type="circle" size="3.572em" />
      <div class="title self-center">
        <q-skeleton class="bg-grey-5" type="QBadge" />
      </div>
      <div class="row self-center justify-between">
        <q-skeleton class="bg-grey-5 self-center" type="QBadge" size="20px" />
        <q-icon
          class="q-ml-lg self-center text-grey"
          size="2em"
          name="navigate_next"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.category {
  background: #ffffff;
  color: #222;
  font-size: 1em;
  font-weight: 500;
  box-shadow: none;
}
</style>
