<template>
  <div class="card-header-points row">
    <button @click="$emit('goBack')" class="button-back-forward self-baseline">
      <q-icon name="fas fa-arrow-left" />
    </button>
    <div class="header-summary width-fit-content-flex">
      <div class="text-center">
        <h6 class="text-h6 title text-uppercase text-weight-medium">
          {{ $t("my_points.points_history") }}
        </h6>
        <points-counter
          :value="total.currentPoints"
          class="text-weight-bold"
          color-class="colorClassWhite"
          style="font-size: 2em; font-weight: 600"
        />
        <p class="q-mt-lg">
          {{ $t("my_points.you_accumulated") }}
          <points-counter
            :value="total.currentPoints"
            style="font-size: 12px"
            color-class="colorClassWhite"
          />
          {{ $t("my_points.last_days") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PointsCounter from "./pointsCounter";

const { useGetters } = createNamespacedHelpers("my_points");

export default {
  name: "CardHeaderPoints",

  components: { PointsCounter },

  emits: ["goBack"],

  setup() {
    const { total } = useGetters({
      total: "getTotalPoints",
    });

    return {
      total,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-header-points {
  background-color: #1a1a1a;
  margin: -25px;
  margin-bottom: 3em;
  color: #c6c6c6;
  .header-summary {
    padding: 0 55px;
    .title {
      font-size: 1em;
    }
  }
  .button-back-forward {
    color: #c6c6c6;
    font-size: 1.4em;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-top: 2em;
    padding: 20px;
    position: absolute;
  }
}
</style>
