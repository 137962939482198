<template>
  <vue3-autocounter
    class="text-counter-size text-grey-8"
    :class="colorClass"
    ref="counter"
    :startAmount="0"
    :endAmount="value"
    :duration="1"
    :decimals="2"
    separator="."
    :autoinit="true"
  />
</template>

<script>
import Vue3AutoCounter from "vue3-autocounter";

export default {
  name: "PointsCounter",

  props: {
    colorClass: {
      default: "colorClassBlack",
    },
    value: {
      type: Number,
      default: 0,
    },
  },

  components: {
    "vue3-autocounter": Vue3AutoCounter,
  },
};
</script>

<style lang="scss">
.colorClassWhite {
  color: #c6c6c6;
}
.text-counter-size {
  font-size: 16pt !important;
}
</style>
